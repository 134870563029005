import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "blackfriday-cc429.firebaseapp.com",
  databaseURL: "https://blackfriday-cc429.firebaseio.com",
  projectId: "blackfriday-cc429",
  storageBucket: "blackfriday-cc429.appspot.com",
  messagingSenderId: "757227800077",
  appId: "1:757227800077:web:24e9c186cb6a2e46f23744"
};

firebase.initializeApp(config);

export default firebase;
