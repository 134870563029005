import React from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import './index.css'
import Coupons from './pages/coupons'
import Login from './pages/login'
import Unauthorized from './pages/unauthorized'
import PrivateRoute from './components/PrivateRoute'

const App = () => (
  <BrowserRouter>
    <Switch>
        <PrivateRoute path="/coupons"  component={Coupons} />
        <Route path="/unauthorized" component={Unauthorized} />
        <Route path="/" component={Login} />
        <Redirect to="/" />
    </Switch>
  </BrowserRouter>
)

export default App
