import React from 'react'
import Button from '../../components/Button'
import styles from './styles.module.css'

import firebase from '../../firebase'

const firebaseAppAuth = firebase.auth()


const Unauthorized = (props) => {

  return (
    <div className={styles.content}>
      <h1>Você não tem permissão para acessar essa página.</h1>
        <div className={styles.loginButton}>
        <Button
        onClick={() =>
          firebaseAppAuth
          .signOut()
          .then(() => (props.history.push('/')))
        }
        size="small"
      >
        voltar para o login
      </Button>
      </div>
    </div>
  )
}


export default Unauthorized
