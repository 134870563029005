import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.css'

const Button = ({ onClick, size, children }) => (
  <button
    onClick={onClick}
    className={classNames(styles.button, styles[size])}
  >
    {children}
  </button>
)


export default Button