import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.css'
import Header from '../../components/Header'
import CouponsList from '../../components/CouponsList'
import Footer from '../../components/Footer'

const Coupons = () => {
  return (
    <div className={classNames(styles.coupon, styles.flexColumn)}>
      <Header />
      <CouponsList />
      <Footer />
    </div>
  )
}

export default Coupons
