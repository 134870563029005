import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

// Since we are not using a mono-spaced font, this is an estimative
// and there might be situations where it behaves oddly.
const maxDescriptionLength = 60

const renderDescription = (description) => {
  let textSliced = description.slice(0, maxDescriptionLength)
  if (textSliced[textSliced.length - 1] === '.') {
    textSliced = textSliced.slice(0, -1)
  }
  return description.length >= maxDescriptionLength
    ? (textSliced += '... ')
    : textSliced
}

const renderShowMore = (onClick) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className={styles.showMore} onClick={onClick}>
      ver mais
    </a>
  )
}

const Description = ({ children, onShowMore }) => {
  return (
    <p className={styles.couponDescription}>
      {renderDescription(children)}
      {children.length > maxDescriptionLength && renderShowMore(onShowMore)}
    </p>
  )
}

Description.propTypes = {
  children: PropTypes.string,
  onShowMore: PropTypes.func,
}

Description.defaultProps = {
  children: '',
  onShowMore: () => {},
}

export default Description
