import React from 'react'

import styles from './styles.module.css'
import Button from '../Button'

const ModalContent = ({ children, code, onClickBuy, onClickClose }) => (
  <div className={styles.modalContent}>
    <button className={styles.exitBtn} onClick={onClickClose}>
      &times;
    </button>
    <h1 className={styles.code}>{code}</h1>
    <p className={styles.description}>{children}</p>
    <Button onClick={onClickBuy} size="small" className={styles.buyBtn}>
      COMPRAR AGORA
    </Button>
  </div>
)

export default ModalContent
