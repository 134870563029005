import React, { useState } from 'react'
import Button from '../Button'
import Modal from '../Modal'

import styles from './styles.module.css'
import Description from './Description'

const Coupon = ({ code, description, link, title }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <div className={styles.couponContent}>
        <p className={styles.couponTitle}>{title}</p>
        <Description onShowMore={() => setModalIsOpen(true)}>
          {description}
        </Description>
        <p className={styles.couponCode}>{code}</p>
        <Button onClick={() => window.open(link)} size="small">
          COMPRAR AGORA
        </Button>
      </div>
      <Modal
        code={code}
        description={description}
        link={link}
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    </>
  )
}

export default Coupon
