import { useEffect, useState } from 'react'

import firebase from '../firebase'

const findCategories = items => [
  ...new Set(
    items.map(({ category }) => category)
  )
]

const buildListCoupons = (coupons, categories) => 
  categories.map(category => ({
    category,
    coupons: coupons.filter(counpon => counpon.category === category)
}))

const useCoupons = () => {  
  const [coupons, setCoupons] = useState([])

  useEffect(() => {
    firebase
      .firestore()
      .collection('coupons')
      .get()
      .then(function(querySnapshot) {
        const newCoupons = querySnapshot.docs.map((item) => item.data())
        const categories = findCategories(newCoupons)
        const result = buildListCoupons(newCoupons, categories)
        setCoupons(result)
      })
  }, [])

  return coupons
}

export default useCoupons
