import React, { Fragment } from 'react'

import Coupon from '../Coupon'
import useCoupouns from '../../hooks/useCoupons'

import styles from './styles.module.css'

const CouponsList = () => {
  const couponsList = useCoupouns()

  return (
    <section>
      {couponsList.map(({
        category,
        coupons
        }) => (
          <Fragment key={category}>
            <h2 className={styles.title}>{category}</h2>
            <div className={styles.couponList}>
              {coupons.map(({ title, code, description, link}) =>
                <Coupon
                  key={`${title}${code}${link}`}
                  code={code}
                  description={description}
                  title={title}
                  link={link}
                />
              )}
            </div>
          </Fragment>
        )
      )}
    </section>
  )
}

export default CouponsList
