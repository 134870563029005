import React, { Fragment } from 'react'

import styles from './styles.module.css'
import stoneCoImage from '../../images/stone_co@2x.png'
import blackFridayImage from '../../images/bf@2x.png'

const Header = () => (
  <Fragment>
    <header className={styles.header}>
      <img
        alt="Stone Co. logo"
        className={styles.stoneCo}
        src={stoneCoImage}
      />
      <img
        alt="Best Friday 2021: paixão por fazer"
        className={styles.blackFriday}
        src={blackFridayImage}
      />
    </header>
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <h2 className={styles.title}>
          Cupons de desconto
        </h2>
        <p className={styles.description}>
        2021 tem sido um ano de recomeço para todo mundo. Nós da Stone Co, sempre fizemos e vamos continuar fazendo o impossível para ajudar nossos clientes. Por isso, nessa página, nós reunimos muitos cupons que garantem vantagens, descontos ou frete grátis nas lojas dos clientes Stone Co Digital.
        </p>
        <p className={styles.description}>
        <b>Essa página é de acesso exclusivo aos nossos times.</b>  Lembre-se de não compartilhar o link externamente. Se for enviar para outra pessoa, divulgue apenas o próprio cupom desejado, copiando ou capturando a tela. <b>Aproveite!</b>
        </p>
      </div>
    </div>
  </Fragment>
)

export default Header
