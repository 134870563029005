import React from 'react'
import ReactModal from 'react-modal'

import ModalContent from './ModalContent'

import styles from './styles.module.css'

const Modal = ({ open, onClose, code, description, link }) => {
  return (
    <ReactModal
      className={styles.modal}
      isOpen={open}
      onRequestClose={onClose}
    >
      <ModalContent
        code={code}
        onClickBuy={() => window.open(link)}
        onClickClose={onClose}
      >
        {description}
      </ModalContent>
    </ReactModal>
  )
}

export default Modal
