import React from 'react'

import styles from './styles.module.css'
import stoneCompany from '../../images/stone_company_bw@2x.png'

const Footer = () => (
  <footer className={styles.footer}>
    <img
      alt="Stone & Pagar.me & Rex & Linx"
      className={styles.stone}
      src={stoneCompany}
    />
  </footer>
)

export default Footer
